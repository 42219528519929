import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";
import { withPrefix } from "gatsby";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Diabetic Shopping List: Best And Worst Foods"
            description="Learn which foods to eat more of and which you should avoid to best regulate your blood sugar levels. A simple diabetic shopping list!"
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Diabetic Shopping List: Best And Worst Foods + FREE Printable
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/diabetic-shopping-list.jpg" alt="Diabetic Shopping List: Best And Worst Foods"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@brookelark?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Brooke Lark</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/diabetic-diet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Did you know that <ExternalLink to = "https://www.cdc.gov/diabetes/library/spotlights/diabetes-facts-stats.html">1 in 10</ExternalLink> Americans have diabetes?
        </Paragraph>
        <Paragraph>
            Even more shocking is the fact that <ExternalLink to = "https://www.cdc.gov/diabetes/library/spotlights/diabetes-facts-stats.html">1 in 5</ExternalLink> don't even know they have it.
        </Paragraph>
        <Paragraph>
            Diabetes is a chronic disease which occurs when the body can't effectively metabolize blood sugar and turn it into energy.
        </Paragraph>
        <Paragraph>
            It has been linked with other health problems such as cardiovascular disease, high cholesterol, and kidney disease.
        </Paragraph>
        <Paragraph>
            Type 1 diabetes is a condition which requires consistent insulin intake. This is a condition which one is born with and there is no cure for it. Type 1 diabetics have to take insulin for the rest of their lives.
        </Paragraph>
        <Paragraph>
            In contrast, the onset of type 2 diabetes can be prevented or delayed with lifestyle changes. If a person is taking medication for type 2 diabetes, it doesn’t necessarily mean they will be taking it for life.
        </Paragraph>
        <Paragraph>
            If a type 2 diabetic makes significant lifestyle changes and manages their diabetes well, there is a chance of recovery.
        </Paragraph>
        <Paragraph>
            And one of the best ways to manage diabetes is through <ExternalLink to = "https://diabetes.org/healthy-living/recipes-nutrition">diet</ExternalLink>.
        </Paragraph>
        <Paragraph>
            In this article, we'll cover the basics of what you'll need in order to start a diabetic meal plan.
        </Paragraph>
        <Paragraph>
            In case you are prediabetic, check out our <InternalLink to = "/shopping-list-for-insulin-resistance-diet/">shopping list for insulin resistance diet</InternalLink> instead.
        </Paragraph>
        <Paragraph>
            Key takeaways from this article:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Focus your diet on non-starchy vegetables, lean protein, and low-fat food options.</UnorderedListItem>
            <UnorderedListItem>Opt for whole grains instead of refined grains.</UnorderedListItem>
            <UnorderedListItem>Avoid processed foods, foods and drinks with added sugar, and high fat dairy.</UnorderedListItem>
            <UnorderedListItem>Read nutrition labels carefully when shopping!</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Diabetic shopping list of foods
        </BlogHeading2>
        <BlogHeading3>
            Non starchy vegetables
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Lettuce</UnorderedListItem>
            <UnorderedListItem>Kale</UnorderedListItem>
            <UnorderedListItem>Tomatoes</UnorderedListItem>
            <UnorderedListItem>Cucumber</UnorderedListItem>
            <UnorderedListItem>Zucchini</UnorderedListItem>
            <UnorderedListItem>Broccoli</UnorderedListItem>
            <UnorderedListItem>Cauliflower</UnorderedListItem>
            <UnorderedListItem>Celery</UnorderedListItem>
            <UnorderedListItem>Carrots</UnorderedListItem>
            <UnorderedListItem>Brussels sprouts</UnorderedListItem>
            <UnorderedListItem>Spinach</UnorderedListItem>
            <UnorderedListItem>Garlic</UnorderedListItem>
            <UnorderedListItem>Onion</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Low glycemic fruits
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Grapefruit</UnorderedListItem>
            <UnorderedListItem>Green apples</UnorderedListItem>
            <UnorderedListItem>Cherries</UnorderedListItem>
            <UnorderedListItem>Peaches</UnorderedListItem>
            <UnorderedListItem>Kiwi</UnorderedListItem>
            <UnorderedListItem>Lemon</UnorderedListItem>
            <UnorderedListItem>Lime</UnorderedListItem>
            <UnorderedListItem>Pears</UnorderedListItem>
            <UnorderedListItem>Berries</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Meat
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Chicken</UnorderedListItem>
            <UnorderedListItem>Turkey</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Fish and seafood
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Salmon</UnorderedListItem>
            <UnorderedListItem>Sardines</UnorderedListItem>
            <UnorderedListItem>Tuna</UnorderedListItem>
            <UnorderedListItem>Herring</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Other protein sources
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Eggs</UnorderedListItem>
            <UnorderedListItem>Tofu</UnorderedListItem>
            <UnorderedListItem>Legumes</UnorderedListItem>
            <UnorderedListItem>Nuts & seeds</UnorderedListItem>
        </UnorderedList><BlogHeading3>
            Dairy
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Low fat milk</UnorderedListItem>
            <UnorderedListItem>Low fat yogurt</UnorderedListItem>
            <UnorderedListItem>Low fat cheese</UnorderedListItem>
            <UnorderedListItem>Cottage cheese</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Alternative dairy options
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Unsweetened soy milk</UnorderedListItem>
            <UnorderedListItem>Unsweetened almond milk</UnorderedListItem>
            <UnorderedListItem>Unsweetened flax milk</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Grains
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Quinoa</UnorderedListItem>
            <UnorderedListItem>Buckwheat</UnorderedListItem>
            <UnorderedListItem>Millet</UnorderedListItem>
            <UnorderedListItem>Amaranth</UnorderedListItem>
            <UnorderedListItem>Brown rice</UnorderedListItem>
            <UnorderedListItem>Oats</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Drinks
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Water</UnorderedListItem>
            <UnorderedListItem>Unsweetened tea</UnorderedListItem>
            <UnorderedListItem>Unsweetened coffee</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            <b>PRO TIP:</b> Add these items to a <InternalLink to = "/#download-section">shopping list app</InternalLink> to carry your diabetic shopping list with you wherever you go!
        </BlockQuote>
        <BlogHeading2>
            Diabetic shopping list printable
        </BlogHeading2>
        <Paragraph>
            <ExternalLink to = {withPrefix("Diabetic Shopping list.pdf")}>Click here to download the diabetic shopping list PDF!</ExternalLink>
        </Paragraph>
        <BlogHeading2>
            How this food list helps regulate diabetes
        </BlogHeading2>
        <Paragraph>
            Now that you have your diabetic shopping list ready, it is important to understand why exactly these foods are good for you and how they will affect your body and overall health.
        </Paragraph>
        <BlogHeading3>
            Vegetables
        </BlogHeading3>
        <Paragraph>
            Vegetables are a no brainer option for anyone looking to improve their health. You should always eat your vegetables, regardless of your current health condition.
        </Paragraph>
        <Paragraph>
            That being said, for diabetics, not all veggies are alike.
        </Paragraph>
        <Paragraph>
            Non starchy veggies listed above are your friend. They have a low glycemic index and will not cause a sudden spike in blood sugar levels once ingested.
        </Paragraph>
        <Paragraph>
            Some non starchy vegetables will even go a step further - for example, broccoli balances blood glucose levels.
        </Paragraph>
        <Paragraph>
            They are also incredibly low in calories, meaning you can pretty much eat as much of them as you want without fear of gaining weight.
        </Paragraph>
        <Paragraph>
            On the other hand, starchy vegetables such as potatoes, sweet potatoes, beets, and similar should be avoided. They have a higher glycemic index and can cause a sudden spike in blood sugar levels after eating.
        </Paragraph>
        <Paragraph>
            Vegetables are also the best source for all kinds of vitamins, minerals, and nutrients your body needs to function properly.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src = "../assets/images/blogs/2023-2-baskets-of-colorful-vegetables-for-sale.jpg" alt = "Baskets of colorful vegetables for sale."></StaticImage>
            <em> Photo by: <ExternalLink to = "https://unsplash.com/es/@adliwahid?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Adli Wahid</ExternalLink> on <ExternalLink to = "https://unsplash.com/s/photos/vegetables?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading3>
            Fruit
        </BlogHeading3>
        <Paragraph>
            Just like veggies, fruits are an excellent source of vitamins and fiber.
        </Paragraph>
        <Paragraph>
            But be mindful of the glycemic index in fruit. It would be great if you could stick to low glycemic fruit options, such as the ones we mentioned in our grocery list.
        </Paragraph>
        <Paragraph>
            You can include berries and citrus fruits into your drinks, smoothies, and even salads.
        </Paragraph>
        <Paragraph>
            Citrus fruits are especially good for you if you have diabetes, because they are high in vitamin C and some <ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5622757/">studies have shown</ExternalLink> that people with type 2 diabetes require more vitamin C in their diet.
        </Paragraph>
        <BlogHeading3>
            Meat
        </BlogHeading3>
        <Paragraph>
            When following any low carb diet, it can be difficult to find foods that make you feel full.
        </Paragraph>
        <Paragraph>
            Meat comes in handy, because it is a very low carb option, but it is also a great source of protein, which makes you feel full for longer periods of time. (At the same time, meat is rich in iron, which is essential for creating healthy red blood cells.)
        </Paragraph>
        <Paragraph>
            Still, make sure you are avoiding red meat, and skipping the skin on poultry because it is high in cholesterol.
        </Paragraph>
        <Paragraph>
            It is also important to watch out for the way the meat is prepared. Avoid fried, stuffed, and overly processed meat.
        </Paragraph>
        <BlogHeading3>
            Fish and seafood
        </BlogHeading3>
        <Paragraph>
            Fish and seafood are another great way to power up your meal.
        </Paragraph>
        <Paragraph>
            Even though on a diabetic diet you should avoid high fat foods, healthy fats should still be a part of your meal plan.
        </Paragraph>
        <Paragraph>
            Salmon, for example, is full of omega 3 fatty acids which are crucial for cardiovascular health.
        </Paragraph>
        <Paragraph>
            In fact, <ExternalLink to = "https://pubmed.ncbi.nlm.nih.gov/28606215/">a study has shown</ExternalLink> that fatty fish is better for regulating blood glucose levels than lean fish.
        </Paragraph>
        <BlogHeading3>
            Alternative protein sources
        </BlogHeading3>
        <Paragraph>
            Some alternative protein sources can also be effective for managing blood glucose levels and increasing the feeling of satiety.
        </Paragraph>
        <Paragraph>
            For example, <ExternalLink to = "https://academic.oup.com/ajcn/article/109/5/1302/5435774">eating a low carb breakfast</ExternalLink> with eggs balances blood sugar levels throughout the whole day.
        </Paragraph>
        <Paragraph>
            Tofu, legumes, nuts and seeds are also great vegan low glycemic food options
        </Paragraph>
        <BlogHeading3>
            Dairy
        </BlogHeading3>
        <Paragraph>
            Dairy is an amazing source of protein and calcium. It can also be effective at balancing your sugar levels.
        </Paragraph>
        <Paragraph>
            For example, did you know that consuming Greek yogurt every day reduces the risk of type 2 diabetes by <ExternalLink to = "https://bmcmedicine.biomedcentral.com/articles/10.1186/s12916-014-0215-1">18%</ExternalLink>?
        </Paragraph>
        <Paragraph>
            Yes, it's true!
        </Paragraph>
        <Paragraph>
        However, make sure you are consuming low fat dairy options because dairy products can be full of saturated fats. Consuming a lot of <ExternalLink to = "https://www.health.harvard.edu/staying-healthy/is-low-fat-or-full-fat-the-better-choice-for-dairy-products">saturated fat increases the risk of heart disease</ExternalLink>.
        </Paragraph>
        <BlogHeading3>
            Whole grains
        </BlogHeading3>
        <Paragraph>
            Grains are full of carbohydrates so they should be consumed in moderation when following a diabetic diet.
        </Paragraph>
        <Paragraph>
            It is very important to focus solely on whole grains like the ones from our list, because they have much <ExternalLink to = "https://pubmed.ncbi.nlm.nih.gov/30321274/">lower impact on blood glucose levels than refined grains</ExternalLink>.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src = "../assets/images/blogs/2023-2-home-baked-bread.jpg" alt = "Home baked bread made from whole grain flour."></StaticImage>
            <em> Photo by: <ExternalLink to = "https://unsplash.com/@wesual?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Wesual Click</ExternalLink> on <ExternalLink to = "https://unsplash.com/s/photos/whole-grains?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading2>
            What 10 foods should a diabetic avoid?
        </BlogHeading2>
        <Paragraph>
            After a comprehensive list of what should be on your diabetic shopping list, here is an overview of the food groups that you should steer clear from.
        </Paragraph>
        <BlogHeading3>
            1. Sugar & sweets
        </BlogHeading3>
        <Paragraph>
            This one should be self explanatory.
        </Paragraph>
        <Paragraph>
            People with diabetes should not be adding sugar to any meal or beverage.
        </Paragraph>
        <Paragraph>
            That includes all types of sweets, such as:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Cakes</UnorderedListItem>
            <UnorderedListItem>Cookies</UnorderedListItem>
            <UnorderedListItem>Chocolate</UnorderedListItem>
            <UnorderedListItem>Candy, and similar</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            2. Processed grains
        </BlogHeading3>
        <Paragraph>
            Processed grains are also not allowed on a diabetic diet.
        </Paragraph>
        <Paragraph>
            That is because processed grains are extremely high in carbs and can cause spikes in blood sugar levels that are hard to control.
        </Paragraph>
        <Paragraph>
            Avoid at all cost:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>White flour</UnorderedListItem>
            <UnorderedListItem>White bread</UnorderedListItem>
            <UnorderedListItem>Pasta made from white flour</UnorderedListItem>
            <UnorderedListItem>White rice</UnorderedListItem>
            <UnorderedListItem>Pastries</UnorderedListItem>
            <UnorderedListItem>Pancakes</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            3. Fried foods
        </BlogHeading3>
        <Paragraph>
            Fried foods are high in trans fats and additives.
        </Paragraph>
        <Paragraph>
            Trans fats have been linked to diabetes and cardiovascular disease.
        </Paragraph>
        <Paragraph>
            Fried foods to avoid on a diabetic diet include:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>French fries</UnorderedListItem>
            <UnorderedListItem>Fried meat</UnorderedListItem>
            <UnorderedListItem>Bacon</UnorderedListItem>
            <UnorderedListItem>Breaded meat</UnorderedListItem>
            <UnorderedListItem>Fried fish</UnorderedListItem>
            <UnorderedListItem>Fried poultry such as chicken nuggets</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            4. High fat dairy
        </BlogHeading3>
        <Paragraph>
            As mentioned before, dairy products are high in saturated fats which, together with diabetes, may lead to the development of heart disease.
        </Paragraph>
        <Paragraph>
            Additionally, milk has the sugar lactose in it. Consuming lactose in large amounts can contribute to high blood sugar levels.
        </Paragraph>
        <Paragraph>
            <ExternalLink to = "https://www.webmd.com/diabetes/what-to-know-about-diabetes-and-milk">Dairy to avoid on a diabetic diet</ExternalLink> includes:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>High fat milk</UnorderedListItem>
            <UnorderedListItem>High fat yogurt</UnorderedListItem>
            <UnorderedListItem>Sweetened yogurt</UnorderedListItem>
            <UnorderedListItem>Cheese</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Low-fat and fat-free dairy products, as well as non-dairy alternatives are the way to go instead.
        </Paragraph>
        <BlogHeading3>
            5. Sweetened drinks
        </BlogHeading3>
        <Paragraph>
            Having some soda or juice with your meal can seem harmless compared to eating, say, a big slice of cake.
        </Paragraph>
        <Paragraph>
            However, sweetened drinks cause sugar spikes and should be avoided.
        </Paragraph>
        <Paragraph>
            Watch out for:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Soda</UnorderedListItem>
            <UnorderedListItem>Fruit juices</UnorderedListItem>
            <UnorderedListItem>Sweetened dairy alternatives such as soy milk or almond milk with added sugar (Non-sweetened options are okay)</UnorderedListItem>
            <UnorderedListItem>Sweetened coffee</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            6. High glycemic fruit & dried fruit
        </BlogHeading3>
        <Paragraph>
            Although we all know we should be eating more fruit, individuals with diabetes need to be careful with fruits that have a high glycemic index.
        </Paragraph>
        <Paragraph>
            Fruits to avoid if you have diabetes:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Ripe bananas</UnorderedListItem>
            <UnorderedListItem>Mangoes</UnorderedListItem>
            <UnorderedListItem>Pineapple</UnorderedListItem>
            <UnorderedListItem>Watermelon</UnorderedListItem>
            <UnorderedListItem>Dates</UnorderedListItem>
            <UnorderedListItem>Oranges</UnorderedListItem>
            <UnorderedListItem>Grapes</UnorderedListItem>
            <UnorderedListItem>Raisins</UnorderedListItem>
            <UnorderedListItem>Dried fruit</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
            <StaticImage src = "../assets/images/blogs/2023-2-dozens-of-bananas 1.jpg" alt = "Dozens of ripe bananas."></StaticImage>
            <em> Photo by: <ExternalLink to = "https://unsplash.com/@rodreis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Rodrigo dos Reis</ExternalLink> on <ExternalLink to = "https://unsplash.com/s/photos/banana?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading3>
            7. Starchy vegetables
        </BlogHeading3>
        <Paragraph>
            Some veggies are also to blame for increases in blood sugar levels.
        </Paragraph>
        <Paragraph>
            That is why if you are diabetic, you should avoid eating these vegetables in high amounts:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Potatoes</UnorderedListItem>
            <UnorderedListItem>Sweet corn</UnorderedListItem>
            <UnorderedListItem>Beetroot</UnorderedListItem>
            <UnorderedListItem>Boiled carrots</UnorderedListItem>
            <UnorderedListItem>Pumpkin</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            8. Processed food & snacks
        </BlogHeading3>
        <Paragraph>
            Processed food is not good for the body regardless of your health status.
        </Paragraph>
        <Paragraph>
            Avoid processed food and snacks such as:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Crackers</UnorderedListItem>
            <UnorderedListItem>Sauces with added sugar</UnorderedListItem>
            <UnorderedListItem>Processed meats such as hot dogs</UnorderedListItem>
            <UnorderedListItem>Chips</UnorderedListItem>
            <UnorderedListItem>Breakfast cereals</UnorderedListItem>
            <UnorderedListItem>Ketchup with added sugar</UnorderedListItem>
            <UnorderedListItem>Sausages</UnorderedListItem>
            <UnorderedListItem>Doughnuts</UnorderedListItem>
            <UnorderedListItem>Ice cream</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            9. Natural sweeteners
        </BlogHeading3>
        <Paragraph>
            With all the hype about processed foods being bad for our health, it could be easy to assume that natural sugar substitutes would be a good choice for someone who is suffering from diabetes.
        </Paragraph>
        <Paragraph>
            However, that could not be further from the truth.
        </Paragraph>
        <Paragraph>
            Similar to sweet fruits, natural sweeteners also need to be avoided on a diabetic diet.
        </Paragraph>
        <Paragraph>
            That includes:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Honey</UnorderedListItem>
            <UnorderedListItem>Agave nectar</UnorderedListItem>
            <UnorderedListItem>Maple syrup</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            10. Poultry skin
        </BlogHeading3>
        <Paragraph>
            When following a diabetic diet, poultry is a safe option for those who consume animal proteins.
        </Paragraph>
        <Paragraph>
            However, poultry skin is high in trans fats and contributes to high cholesterol levels.
        </Paragraph>
        <Paragraph>
            Therefore, make sure you always remove the skin when eating chicken or turkey.
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            Diabetes is a serious health condition that can be managed with lifestyle changes such as introducing a diabetic diet, increasing the level of physical activity, eliminating cigarettes, and similar.
        </Paragraph>
        <Paragraph>
            There are many tasty food options for diabetics, including a whole array of veggies, salmon, chicken, and nuts and seeds.
        </Paragraph>
        <Paragraph>
            Make sure you are avoiding high glycemic foods and anything with added sugar. Read labels carefully!
        </Paragraph>
        <Paragraph>
            If you were inspired by this article, check out our <InternalLink to = "/healthy-pantry-staples/">healthy pantry staples list</InternalLink>!
        </Paragraph>


    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;